<template>
	<div class="loaded has-footer">
		<b-container class="content intro" fluid>
			<b-row>
				<b-col>
					<h1 class="mb-3">Submit a Report</h1>
					<h3 class="mb-35">{{title}}</h3>
					<template v-if="!alreadySaved">
						<p class="mb-2"><b>Why create a password?</b></p>
						<h4 class="mb-35">
							<template v-if="!isSubmit">If you wish to save your Report to finish later, you will need to create a password.<br><br></template>
							Creating a password is optional but you must have one if you want to:
							<ul>
								<li>
									get back to this report to add more information later
								</li>
								<li>
									find out how the matter is progressing after you have submitted it
								</li>
							</ul>
							For your security, we will never give out details about a submitted report, online or by phone, unless you have a password. You can still remain anonymous if you create a password.
						</h4>
					</template>
					<h4 v-else-if="hasPassword" class="mb-35">
						<strong>Your password has been saved</strong><br>
						You can use your password and the report ID to continue the report at a later date. Once the report has been completed, you will have access to a portal for private messages and status updates regarding the report. 
					</h4>
					<h4 v-else class="mb-35">
					<strong>Your report has been submitted</strong><br>
					If you have supplied contact details we may contact you at some stage to clarify or substantiate your claim. 
					</h4>
					<img src="/img/secure.svg" class="mr-3"><i>Safe, secure, confidential</i>
				</b-col>
			</b-row>
		</b-container>
		<transition name="fade" mode="out-in">
			<b-form v-if="!alreadySaved" @submit.prevent="submit()">
				<b-container class="content body pb-3" fluid>
					<b-row>
						<b-col>
							<!-- <report-id class="mb-35 bg-white" /> -->
							<password-form-group :password.sync="password" :show-invalid="isSubmitted" class="mb-35" is-new>
								<template v-if="!isSubmit" slot="error">You must enter a password of at least 10 characters before you can save a report.</template>
								<template v-else slot="error">You must enter a secure password to submit a report. Or you may click the 'Submit without Password' link below.</template>
							</password-form-group>
							<b-form-group
								label="Email"
								label-for="email"
								label-class="optional"
								class="mb-3"
							>
								<b-form-input id="email" v-model="email" type="email" name="email" :readonly="fixautofill" @focus="fixautofill=false"></b-form-input>
							</b-form-group>
							<p class="font-italic sm">
								<b>Why do we ask for an email address?</b> If you forget your password, you can request a password reset link sent to this email address. If you don’t provide an email address, please make very sure to remember your password. 
							</p>
						</b-col>
					</b-row>
				</b-container>
				<b-container class="content footer" fluid>
					<b-row class="maw-md-row">
						<b-col>
							<!-- <div v-if="isSubmit" class="mb-4">
								<b-button class="download-report sm py-3" size="lg" variant="light"><b-icon-file-text /> Download a copy of my Report</b-button>
							</div> -->
							<div class="d-flex align-items-center">
								<btn-back />
								<b-button
									type="submit"
								>
									{{isSubmit ? 'Submit' : 'Save'}}
								</b-button>
							</div>
							<a v-if="isSubmit" href="#" class="mt-3 float-right" @click.prevent="submit(false)">Submit without Password</a>
						</b-col>
					</b-row>
				</b-container>
			</b-form>
			<div v-else class="has-footer">
				<b-container class="content body" fluid>
					<b-row>
						<b-col>
							<label for>Report ID</label>
							<report-id white />
						</b-col>
					</b-row>
				</b-container>
				<b-container class="content footer" fluid>
					<b-row class="maw-sm-row">
						<b-col>
							<div v-if="isSubmit" class="mb-4">
								<b-button class="download-report sm py-3" variant="light" @click.prevent="downloadReport"><b-icon-file-text /> Download my Report</b-button>
							</div>
							<!-- <btn-back v-if="!isSubmit" /> -->
							<div class="d-flex">
								<b-button
									class="ml-auto"
									@click.prevent="clickContinue"
								>
									Continue
								</b-button>
							</div>
						</b-col>
					</b-row>
				</b-container>
			</div>
		</transition>
	</div>
</template>

<script>
import {isCreatePassword, canDownloadReport, hasPassword} from '@/mixins'
export default {
	name: 'CreatePassword',          
	mixins: [isCreatePassword, canDownloadReport, hasPassword],
}
</script>